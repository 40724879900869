import React from 'react';

const BabyVaccinationSvg = ({ disabled = false }) => {
  return (
    <div>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="4" fill="#49C1C3" />
        <g clip-path="url(#clip0_25504_5482)">
          <path d="M29 15L33 19" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M31 17L26.5 21.5" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M23.5 18.5L29.5 24.5" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M28.5 23.5L22 30H18V26L24.5 19.5" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19.5 24.5L21 26" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22.5 21.5L24 23" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M15 33L18 30" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_25504_5482">
            <rect width="24" height="24" fill="white" transform="translate(12 12)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BabyVaccinationSvg;