import React from 'react';
import { Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';

const NameTile = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const { firstName, age, gender, bloodGroup } = customization.userData ?? {};

  const styles = {
    patientInfo: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      margin: '20px 0',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      marginBottom: '20px',
    },
  };

  return (
    <Card style={styles.patientInfo}>
      <Typography variant="h2" color='white' fontWeight='bold'>{firstName}</Typography>
      <Typography variant="h3" color='white' fontWeight='bold'>{age} y | {gender} | {bloodGroup}</Typography>
    </Card>
  );
};

export default NameTile;
