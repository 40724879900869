import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import HealthHistory from "views/HealthHistory";
import PatientDashboard from "views/PatientDashboard";
import PDFViewer from "views/PDFViewer";
import LabScan from "views/LabScan";
import InvalidLinkMessage from "ui-component/InvalidLinkMessage";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// const Documentation = Loadable(lazy(() => import("views/documentation")));

// sample page routing

const NewEmbedTableauPage = Loadable(
  lazy(() => import("views/newTableauPage"))
);

const ViewVitalsPage = Loadable(lazy(() => import("views/viewVitalsPage")));

const CreateAppointmentSec = Loadable(
  lazy(() => import("views/createAppointmentPageSec"))
);

const MenstrualPage = Loadable(lazy(() => import("views/MenstrualPage")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  children: [
    {
      path: "/landing",
      element: <DashboardDefault />,
    },
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/create-appointment",
      element: <CreateAppointmentSec />,
    },
    {
      path: "/tableau-page",
      element: <NewEmbedTableauPage />,
    },
    {
      path: "/view-vitals",
      element: <ViewVitalsPage />,
    },
    {
      path: "/menstrual",
      element: <MenstrualPage />,
    },
    {
      path: "/health-history",
      element: <HealthHistory />,
    },
    {
      path: "/dashboard",
      element: <PatientDashboard />,
    },
    {
      path: "/pdf",
      element: <PDFViewer />,
    },
    {
      path: "/labScanReports",
      element: <LabScan/>,
    },
    // Wildcard route for unmatched paths
    {
      path: "*",
      element: <InvalidLinkMessage />, // Display your 404 component
    },
  ],
};

export default MainRoutes;
