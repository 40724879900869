import React, {useEffect, useState} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Typography, IconButton, Grid, Box} from '@mui/material';
import moment from 'moment';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';

const Section = ({data}) => (
  <Box
    border={1}
    borderColor='#33CCCC'
    p={2}
    borderRadius={2}
    mb={1}
  >
    {Object.entries(data).map(([key, value]) => (
      <Grid container alignItems='flex-start'>
        <Grid item xs={4}>
          <Typography variant="subtitle1" m={0} p={0} color='#33CCCC' fontWeight='regular'>{key}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1" color='white' fontWeight='bold'>{value}</Typography>
        </Grid>
      </Grid>
    ))}
  </Box>
);

const HealthHistory = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);

  const customization = useSelector((state) => state.customization);
  const hlogPersonId = parseInt(customization.userData.hlogPersonId, 0);

  const parseData = (data) => {
    const {result: {patientTreatmentDetails, patientAllergyDetails, patientOperationDetails, patientAccidentDetails} = {}} = data ?? {};

    const healthConditions = patientTreatmentDetails?.map(t => ({
      'Health conditions': t.treatingHealthConditions,
      'Hospital/Clinic': t.hospitalName,
      'Area/City': `${t.area?.trim()},${t.city}`
    }));

    const allergies = patientAllergyDetails?.map(a => ({
      'Allergies': a.allergeticMedicine
    }));

    const operations = patientOperationDetails?.map(o => ({
      'Operation Performed': o.operationName,
      'Operation Date': moment(o.operationDate).format('DD/MM/YYYY'),
      'Hospital/Clinic': o.hospitalName,
      'Area/City': `${o.area?.trim()},${o.city}`
    }));

    const accidents = patientAccidentDetails?.map(a => a.isOperationDone ?
      {
        'Accident Date': moment(a.accidentDate).format('DD/MM/YYYY'),
        'Accident Injury': a.accidentInjury,
        'Operation Performed': a.operationName,
        'Operation Date': moment(a.operationDate).format('DD/MM/YYYY'),
        'Hospital/Clinic': a.hospitalName,
        'Area/City': `${a.area?.trim()},${a.city}`
      }
      : {
        'Accident Date': moment(a.accidentDate).format('DD/MM/YYYY'),
        'Accident Injury': a.accidentInjury,
        'Hospital/Clinic': a.hospitalName,
        'Area/City': `${a.area?.trim()},${a.city}`
      });

    setHistory([
      ...healthConditions,
      ...operations,
      ...accidents,
      ...allergies,
    ]);
  };

  useEffect(() => {

    if (!hlogPersonId) return;

    fetch(`${process.env.REACT_APP_BASEURL}/v1/my/treatment/doctor?hlogpersonid=${hlogPersonId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': 'Ymge2Q1tLkB3dUwqdS6vtTY41I35tKQh5lgWKa2pHCHX0e7q9j6XIyzMgfYubvPL'
      },
    })
      .then((response) => response.json())
      .then(parseData)
      .catch(() => console.log("Error while fetching the health history data"))
      .finally(() => setLoading(false));
  }, [hlogPersonId]);

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <IconButton style={{color: '#33CCCC'}} onClick={() => navigate("/hlog/landing")}>
            <ChevronLeftIcon style={{fontSize: 32}} />
            <Typography variant="subtitle1" color='#33CCCC'>Back</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={10} justifyContent="center" textAlign='center'>
          <Typography variant="h3" color='white'>Health History</Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>


      {loading
        ? <Label text='Loading...' />
        : history?.length
          ? history?.map(detail => <Section data={detail} />)
          : <Label text='No health information recorded by the user.' />
      }
    </Box >
  );
};

const Label = ({text}) => <Typography variant="h3" color='white' style={{textAlign: 'center', marginTop: 30}}>{text}</Typography>;

export default HealthHistory;