import { Card, Typography } from '@mui/material';
import BloodReportSvg from 'assets/images/BloodReportSvg';
import CTScanSvg from 'assets/images/CTScanSvg';
import MriScanSvg from 'assets/images/MriScanSvg';
import PetScanSvg from 'assets/images/PetScanSvg';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import RightArrowSvg from 'assets/images/icons/RightArrowSvg';
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import useExtractRawQueryParams from 'hooks/useExtractQueryParams';
import NameTile from 'ui-component/NameTile';
import Logo from 'ui-component/Logo';
import BackButton from 'ui-component/backButton/BackButton';

const items = [
  { label: 'Blood Reports', value: 'blood', route: '/hlog/tableau-page', icon: BloodReportSvg, reportKey: "bloodReportStatus" },
  { label: 'CT Scan Reports', value: 'ct', route: '/hlog/pdf', icon: CTScanSvg, reportKey: "ctStatus" },
  { label: 'MRI Scan Reports', value: 'mri', route: '/hlog/pdf', icon: MriScanSvg, reportKey: "mriStatus" },
  { label: 'PET Scan Reports', value: 'pet', route: '/hlog/pdf', icon: PetScanSvg, reportKey: "petStatus" },
];

const ReportCard = ({ title, Icon, disabled, onClick }) => {
    const theme = useTheme();
    const styles = {
        reportCard: {
            borderRadius: '5px',
            margin: '10px 0',
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            cursor: disabled ? 'not-allowed' : 'pointer',
            transition: 'background-color 0.3s ease',
            backgroundColor: disabled ? '#41586E' : theme.palette.background.paper,
            opacity: disabled ? 0.4 : 1,
        },
    };

    return (
        <Card style={styles.reportCard} onClick={() => !disabled && onClick()}>
            <Icon />
            <Typography
                variant="h3"
                color="white"
                fontWeight="bold"
                textAlign="left"
                flex={1}
                marginLeft={2}
            >
                {title}
            </Typography>
            <RightArrowSvg />
        </Card>
    );
};

const LabScan = () => {
    const navigate = useNavigate();
  const theme = useTheme();

  const customization = useSelector((state) => state.customization);


  const [options] = useState(items);

  const {encryptedValue, key} = useExtractRawQueryParams();

  const styles = {
    container: {
      color: '#FFFFFF',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: "15px",
      backgroundColor: "#2c3e50"
    },
    header: {
      textAlign: 'center',
      width: '100%',
      marginTop: '5px',
    },
    reportCards: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
    },
  };


  const handleBack = () => {
    navigate(`/hlog/dashboard${window.location.search}`);
  };


  const navigateTo = async (item) => {
    try {
      if (item.route === '/hlog/dashboard') {
        navigate(`/hlog/dashboard${window.location.search}`);
      } else {
        navigate(`${item.route}${window.location.search}`, {state: {url: `${process.env.REACT_APP_BASEURL}/v2/report/${item.value}/${encryptedValue}/${key}`, name: item.label}});
      }
    } catch (e) {
      alert('Something went wrong! Please retry.');
    }
  };

  return (
    <div style={styles.container}>
        <div>
            <BackButton  onClick={handleBack}/>
        </div>
    <header style={styles.header}>
      <Logo/>
      <Typography variant="h2" color='white' fontWeight='bold'>View Lab and Scan Reports</Typography>
    </header>
    <NameTile />
      <div style={styles.reportCards}>
        {options?.map((option, idx) =>
          <ReportCard key={option.value} title={option.label} Icon={option.icon} disabled={ customization.userData[option.reportKey] === "not-avaialble"} onClick={() => navigateTo(option)} />
        )}
      </div>
    </div>
  );
};


export default LabScan;
