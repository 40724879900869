import React from 'react';

const LabScanSvg = ({ disabled = false }) => {
  return (
        <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="4" fill="#49C1C3"/>
    <g clip-path="url(#clip0_25504_5437)">
    <g clip-path="url(#clip1_25504_5437)">
    <path d="M30.5907 34H18.413C18.4032 33.9918 18.3946 33.9781 18.3841 33.9762C17.5279 33.8142 16.9957 33.0566 17 32.1429C17.0196 27.8153 17.0086 23.4874 17.009 19.1595C17.009 19.075 17.0078 18.9901 17.0125 18.9059C17.0575 18.1022 17.6723 17.4338 18.4733 17.3587C18.9053 17.318 19.3428 17.3332 19.7779 17.3254C19.9575 17.3223 20.1372 17.325 20.3312 17.325C20.3312 17.0593 20.3293 16.8198 20.3316 16.5804C20.3371 16.0192 20.6642 15.6799 21.2234 15.6635C21.5224 15.6549 21.8218 15.6616 22.1207 15.6616C22.3524 15.6616 22.5844 15.6616 22.8317 15.6616C22.8564 15.2104 22.9988 14.8273 23.2997 14.515C23.6014 14.2027 23.9716 14.0313 24.4048 14.0036C24.7664 13.9805 25.1025 14.0697 25.4046 14.2673C25.9012 14.5921 26.136 15.073 26.1782 15.6616C26.7026 15.6616 27.2027 15.6561 27.7028 15.6647C27.8562 15.6674 28.0217 15.6784 28.1595 15.7371C28.5136 15.8877 28.6678 16.1836 28.6721 16.5616C28.6748 16.8124 28.6725 17.0632 28.6725 17.3254C29.2148 17.3254 29.7278 17.3227 30.2408 17.3262C30.7875 17.3301 31.277 17.498 31.6046 17.9503C31.7783 18.1906 31.8683 18.4915 31.9959 18.7651V32.5937C31.9822 32.6285 31.9642 32.6621 31.9556 32.6981C31.8562 33.1028 31.6511 33.4471 31.3037 33.6741C31.0873 33.8153 30.8298 33.8936 30.5907 34ZM28.6725 19.0057C28.6725 19.2659 28.674 19.5062 28.6725 19.7465C28.6678 20.3209 28.3203 20.6723 27.7458 20.6727C25.5866 20.6747 23.4273 20.6743 21.268 20.6727C20.6815 20.6723 20.3359 20.3233 20.332 19.7363C20.3305 19.4972 20.332 19.2581 20.332 19.0139H18.6967V32.3107H30.3082V19.0053H28.6725V19.0057ZM26.9914 17.3512H22.0194V18.9901H26.9914V17.3512Z" fill="#41586E"/>
    <path d="M20.3424 30.6612V29.0115H28.661V30.6612H20.3424Z" fill="#41586E"/>
    <path d="M26.9937 23.9918V25.667H25.3423V27.3191H23.6718V25.6784H22.0201V24.0028H23.6593V22.342H25.329V23.9922H26.9941L26.9937 23.9918Z" fill="#41586E"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_25504_5437">
    <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
    </clipPath>
    <clipPath id="clip1_25504_5437">
    <rect width="14.9959" height="20" fill="white" transform="translate(17 14)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  );
};

export default LabScanSvg;
