import React from 'react';

const CreateAppointmentSvg = ({ disabled = false }) =>{
    return(
        <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="5" fill="#49C1C3"/>
<path d="M16.3844 17.0769C16.0172 17.0769 15.665 17.2228 15.4053 17.4825C15.1457 17.7421 14.9998 18.0943 14.9998 18.4615V31.6154C14.9998 31.9826 15.1457 32.3348 15.4053 32.5945C15.665 32.8541 16.0172 33 16.3844 33H31.6152C31.9824 33 32.3346 32.8541 32.5942 32.5945C32.8539 32.3348 32.9998 31.9826 32.9998 31.6154V18.4615C32.9998 18.0943 32.8539 17.7421 32.5942 17.4825C32.3346 17.2228 31.9824 17.0769 31.6152 17.0769H28.8459M19.1536 15V19.1538M28.8459 15V19.1538M19.1536 17.0769H26.0767" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.8756 20.6243C22.692 20.6243 22.5159 20.6972 22.3861 20.827C22.2563 20.9569 22.1833 21.133 22.1833 21.3166V23.2633H20.2338C20.0502 23.2633 19.8741 23.3363 19.7442 23.4661C19.6144 23.5959 19.5415 23.772 19.5415 23.9557V26.2057C19.5415 26.3893 19.6144 26.5654 19.7442 26.6952C19.8741 26.825 20.0502 26.898 20.2338 26.898H22.1819V28.8447C22.1819 29.0283 22.2549 29.2044 22.3847 29.3343C22.5145 29.4641 22.6906 29.537 22.8742 29.537H25.1242C25.2152 29.537 25.3052 29.5191 25.3892 29.4843C25.4732 29.4495 25.5495 29.3986 25.6138 29.3343C25.6781 29.27 25.7291 29.1937 25.7639 29.1097C25.7986 29.0257 25.8166 28.9356 25.8166 28.8447V26.898H27.7633C27.9469 26.898 28.123 26.825 28.2529 26.6952C28.3827 26.5654 28.4556 26.3893 28.4556 26.2057V23.9557C28.4556 23.772 28.3827 23.5959 28.2529 23.4661C28.123 23.3363 27.9469 23.2633 27.7633 23.2633H25.8179V21.3166C25.8179 21.133 25.745 20.9569 25.6152 20.827C25.4853 20.6972 25.3092 20.6243 25.1256 20.6243H22.8756Z" stroke="#41586E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
    );
};

export default CreateAppointmentSvg;

